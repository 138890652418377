import _ from 'lodash';
import React, { useState, useEffect } from "react";
import classes from './Report.module.scss';
import { useTranslation } from "react-i18next";
import Search from './Search/Search';
import Table from './Table/Table';
import table from './table';

const Report = () => {
    const { t } = useTranslation();
    const [nameFilter, setNameFilter] = useState('');
    const [regionFilter, setRegionFilter] = useState('all');
    const [allFilter, setAllFilter] = useState(true);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows(table);
    }, []);

    useEffect(() => {
        const nowDay = _.toNumber(String(new Date().getDate()).padStart(2, '0'));
        const nowMonth = _.toNumber(String(new Date().getMonth()).padStart(2, '0')) + 1;
        setRows(() =>
            table
                .filter(row => _.toLower(row["Full Name"]).includes(_.toLower(nameFilter)))
                .filter(row => {
                    if (allFilter)
                        return true;
                    const rowDay = _.toNumber(_.words(row["Date"])[0]);
                    const rowMonth = _.toNumber(_.words(row["Date"])[1]);
                    return (rowDay >= nowDay && rowDay <= nowDay + 7 && rowMonth === nowMonth) || (rowMonth === nowMonth + 1 && nowDay - rowDay > 24);
                })
                .filter(row => regionFilter === 'all' || row["Region"] === regionFilter)
        );
    }, [nameFilter, regionFilter, allFilter]);

    return (
        <section>
            <div className={classes.wrap}>
                <div className='container'>
                    <Search
                        nameFilter={nameFilter}
                        setNameFilter={setNameFilter}
                        allFilter={allFilter}
                        setRegionFilter={setRegionFilter}
                        setAllFilter={setAllFilter}
                    />
                </div>
                <div className={`container ${classes.tableContainer}`}>
                    <Table rows={rows} setRows={setRows} />
                </div>
            </div>
        </section>
    );
};

export default Report;

import React from "react";
import classes from './Ready.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image from '../../../assets/img/InvestorsPage/ready.png';

const Ready = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.wrap}>
            <div className="container">
                <div className={classes.body}>
                    <h2 className={`${classes.title} font-48`}>
                        {t('investors.ready.title')}
                    </h2>
                    <p className={`${classes.text} font-32`}>
                        {t('investors.ready.text')}
                    </p>
                    <Button toForm>
                        {t('btn.start_trading')}
                    </Button>
                </div>
            </div>
            <img className={classes.image} src={image} alt='' />
        </section>
    );
};

export default Ready;

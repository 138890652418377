import React, { useState } from "react";
import classes from './Faqs.module.scss';
import { useTranslation } from "react-i18next";

const tabs = [
    {
        subtitle: 'ipo.faqs.tab_01.subtitle',
        desc: 'ipo.faqs.tab_01.desc',
    },
    {
        subtitle: 'ipo.faqs.tab_02.subtitle',
        desc: 'ipo.faqs.tab_02.desc',
    },
    {
        subtitle: 'ipo.faqs.tab_03.subtitle',
        desc: 'ipo.faqs.tab_03.desc',
    },
    {
        subtitle: 'ipo.faqs.tab_04.subtitle',
        desc: 'ipo.faqs.tab_04.desc',
    },
    {
        subtitle: 'ipo.faqs.tab_05.subtitle',
        desc: 'ipo.faqs.tab_05.desc',
    },
];

const Faqs = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    return (
        <section className="container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-48`}>
                    {t('ipo.faqs.title')}
                </h2>
                <div className={classes.tabs}>
                    {tabs.map((tab, index) =>
                        <div
                            key={index}
                            onClick={() => setActive(index)}
                            className={`${classes.tab} ${active === index ? classes.active : ''} no-select font-20`}
                        >
                            <div className={classes.head}>
                                <h4 className={classes.subtitle}>
                                    {t(tab.subtitle)}
                                </h4>
                                <div className={classes.status}>

                                </div>
                            </div>
                            <p className={classes.desc}>
                                {t(tab.desc)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Faqs;

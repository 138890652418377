import React, { useState } from "react";
import classes from './Tabs.module.scss';
import { Trans, useTranslation } from "react-i18next";

const tabs = [
    {
        title: 'ipo.tabs.tab_01.title',
        text: 'ipo.tabs.tab_01.text',
    },
    {
        title: 'ipo.tabs.tab_02.title',
        text: 'ipo.tabs.tab_02.text',
        list: [
            {
                subtitle: 'ipo.tabs.tab_02.li_01.subtitle',
                desc: 'ipo.tabs.tab_02.li_01.desc',
            },
            {
                subtitle: 'ipo.tabs.tab_02.li_02.subtitle',
                desc: 'ipo.tabs.tab_02.li_02.desc',
            },
        ]
    },
    {
        title: 'ipo.tabs.tab_03.title',
        list: [
            {
                subtitle: 'ipo.tabs.tab_03.li_01.subtitle',
                desc: 'ipo.tabs.tab_03.li_01.desc',
            },
            {
                subtitle: 'ipo.tabs.tab_03.li_02.subtitle',
                desc: 'ipo.tabs.tab_03.li_02.desc',
            },
            {
                subtitle: 'ipo.tabs.tab_03.li_03.subtitle',
                desc: 'ipo.tabs.tab_03.li_03.desc',
            },
        ]
    },
];

const Tabs = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    return (
        <section className="container">
            <div className={classes.wrap}>
                <div className={classes.buttons}>
                    {tabs.map((tab, index) =>
                        <div
                            key={index}
                            onClick={() => setActive(index)}
                            className={`${classes.button} ${active === index ? classes.active : ''} no-select font-20`}
                        >
                            {t(tab.title)}
                        </div>
                    )}
                </div>
                <div className={classes.tab}>
                    <h2 className={`${classes.title} font-48`}>
                        {t(tabs[active].title)}
                    </h2>
                    {tabs[active].text &&
                        <div className={`${classes.text} font-20`}>
                            {t(tabs[active].text)}
                        </div>
                    }
                    {tabs[active].list &&
                        <ul className={`${classes.list} font-20`}>
                            {tabs[active].list.map((li, index) =>
                                <li key={index} className={classes.li}>
                                    <p className={classes.subtitle}>
                                        {t(li.subtitle)}
                                    </p>
                                    <p className={classes.desc}>
                                        <Trans>
                                            {t(li.desc)}
                                        </Trans>
                                    </p>
                                </li>
                            )}
                        </ul>
                    }
                </div>
            </div>
        </section>
    );
};

export default Tabs;

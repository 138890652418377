import React from "react";
import classes from "./Button.module.scss";

const Button = ({ children, login, customLink, event, toForm }) => {

  const scrollToForm = () => {
    const element = document.getElementById('feedback__form');
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  };

  if (toForm) {
    return (
      <div
        onClick={scrollToForm}
        className={classes.button}
      >
        <span>{children}</span>
      </div>
    );
  }

  if (event) {
    return (
      <div
        onClick={event}
        className={classes.button}
      >
        <span>{children}</span>
      </div>
    );
  }

  if (customLink) {
    return (
      <a
        href={customLink}
        className={classes.button}
      >
        <span>{children}</span>
      </a>
    );
  }

  return (
    <a
      href={`${process.env.REACT_APP_CABINET_LINK}/${login ? 'login' : 'signup'}`}
      className={classes.button}
    >
      <span>{children}</span>
    </a>
  );
};

export default Button;

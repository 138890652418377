import { Helmet } from "react-helmet-async";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Banner from "../../components/InvestorsPage/Banner/Banner";
import Quaterly from "../../components/InvestorsPage/Quaterly/Quaterly";
import Ready from "../../components/InvestorsPage/Ready/Ready";
import Report from "../../components/InvestorsPage/Report/Report";
import Form from "../../components/InvestorsPage/Form/Form";

const InvestorsPage = () => {
  return (
    <>
      <Helmet>
        <title>GMG (Dubai) Limited</title>
        <meta name="title" content="GMG (Dubai) Limited - A Secure and User-Friendly Trading Platform for Cryptocurrencies" />
        <meta property="og:title" content="GMG (Dubai) Limited - A Secure and User-Friendly Trading Platform for Cryptocurrencies" />
        <meta property="twitter:title" content="GMG (Dubai) Limited - A Secure and User-Friendly Trading Platform for Cryptocurrencies" />

        <meta name="description" content="Join GMG (Dubai) Limited, the leading trading platform for buying and selling cryptocurrencies in a safe and easy-to-use environment. Enjoy competitive fees, fast transaction speeds, and reliable customer support. Sign up now and start trading today!" />
        <meta property="og:description" content="Join GMG (Dubai) Limited, the leading trading platform for buying and selling cryptocurrencies in a safe and easy-to-use environment. Enjoy competitive fees, fast transaction speeds, and reliable customer support. Sign up now and start trading today!" />
        <meta property="twitter:description" content="Join GMG (Dubai) Limited, the leading trading platform for buying and selling cryptocurrencies in a safe and easy-to-use environment. Enjoy competitive fees, fast transaction speeds, and reliable customer support. Sign up now and start trading today!" />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Quaterly />
        <Ready />
        <Report />
        <Form />
        <Footer />
      </div>
    </>
  );
};

export default InvestorsPage;

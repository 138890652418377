import React from "react";
import classes from './Quaterly.module.scss';
import { useTranslation } from "react-i18next";

const Quaterly = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-48`}>
                    {t('investors.quaterly.title')}
                </h2>
                <div className={`${classes.text} font-20`}>
                    <p>
                        {t('investors.quaterly.text_01')}
                    </p>
                    <p>
                        {t('investors.quaterly.text_02')}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Quaterly;

import React from "react";
import classes from './Benefits.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/IpoPage/benefits.png';

const Benefits = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-48`}>
                        {t('ipo.benefits.title')}
                    </h2>
                    <ul className={`${classes.list} font-20`}>
                        <li className={classes.li}>
                            <p className={classes.subtitle}>
                                {t('ipo.benefits.li_01.subtitle')}
                            </p>
                            <p className={classes.desc}>
                                {t('ipo.benefits.li_01.desc')}
                            </p>
                        </li>
                        <li className={classes.li}>
                            <p className={classes.subtitle}>
                                {t('ipo.benefits.li_02.subtitle')}
                            </p>
                            <p className={classes.desc}>
                                {t('ipo.benefits.li_02.desc')}
                            </p>
                        </li>
                        <li className={classes.li}>
                            <p className={classes.subtitle}>
                                {t('ipo.benefits.li_03.subtitle')}
                            </p>
                            <p className={classes.desc}>
                                {t('ipo.benefits.li_03.desc')}
                            </p>
                        </li>
                    </ul>
                    <p className={`${classes.text} font-20`}>
                        {t('ipo.benefits.text')}
                    </p>
                </div>
                <img className={classes.image} src={image} alt='' />
            </div>
        </section>
    );
};

export default Benefits;
